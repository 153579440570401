import { Link } from "gatsby-plugin-i18next"
import React from "react"
import { translate } from "react-i18next"

function Pagination({
  pageInfo: { hasPreviousPage, currentPage, pageCount, hasNextPage },
  t,
}) {
  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        {hasPreviousPage && (
          <li className="page-item">
            <Link
              className="page-link"
              to={`/news/${currentPage <= 2 ? "" : currentPage - 1}`}
            >
              {t("previous")}
            </Link>
          </li>
        )}
        {Array.from({ length: pageCount }, (x, i) => (
          <li className={`page-item ${i + 1 === currentPage && "active"}`}>
            <Link
              className="page-link"
              to={`/news/${i + 1 === 1 ? "/" : i + 1}`}
            >
              {i + 1}
            </Link>
          </li>
        ))}
        {hasNextPage && (
          <li className="page-item">
            <Link className="page-link" to={`/news/${currentPage + 1}`}>
              {t("next")}
            </Link>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default translate()(Pagination)
