import { Card, Col } from "react-bootstrap"

import Img from "gatsby-image"
import { Link } from "gatsby-plugin-i18next"
import React from "react"

export function NewsCard({ news }) {
  return (
    <Col sm="12" lg="4" className="d-flex">
      <Card border="primary" className="mb-4">
        <Img className="card-img-top" fluid={news.node.cover.fluid} />
        <Card.Body>
          <h5>
            <Link to={`/post/${news.node.slug}`}>{news.node.title}</Link>
          </h5>
          <Card.Text>{news.node.shortDescription}</Card.Text>
        </Card.Body>
        <Card.Footer>{news.node.publishedAt}</Card.Footer>
      </Card>
    </Col>
  )
}

export default NewsCard
