import React from "react"
import { Link } from "gatsby-plugin-i18next"

const ProjectCard = ({ title, slug, body }) => (
  <Link to={`/project/${slug}`}>
    <img src="//placehold.it/300" alt={title} />
    <h5>{title}</h5>
    <p>{body}</p>
  </Link>
)

export default ProjectCard
