/* eslint-disable react/display-name */
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="text-justify">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { title, description, file } = node.data.target.fields
      const mimeType = file["en"].contentType
      const mimeGroup = mimeType.split("/")[0]

      switch (mimeGroup) {
        case "image":
          return (
            <img
              title={title ? title["en"] : null}
              alt={description ? description["en"] : null}
              src={file["en"].url}
            />
          )
        case "application":
          return (
            <a
              alt={description ? description["en"] : null}
              href={file["en"].url}
            >
              {title ? title["en"] : file["en"].details.fileName}
            </a>
          )
        default:
          return (
            <span style={{ backgroundColor: "red", color: "white" }}>
              {" "}
              {mimeType} embedded asset{" "}
            </span>
          )
      }
    },
  },
}

export default bodyRichText => documentToReactComponents(bodyRichText, options)
