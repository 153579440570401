import { Layout, SEO } from "../components"

import I18n from "react-i18next/dist/commonjs/I18n"
import React from "react"
import { graphql } from "gatsby"
import { withI18next } from "gatsby-plugin-i18next"

const Thanks = () => (
  <I18n>
    {t => (
      <Layout>
        <SEO title={t("thanks")} />
        <h1>{t("thanks")}</h1>
      </Layout>
    )}
  </I18n>
)

export default withI18next()(Thanks)
export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`
